#root, .App {
  height: 100%;
  width: 100%;
}

body {
  font-size: min(4vw, 4vh) ;
}


.App {
  text-align: center;
  height: 100%;
}

.overlay {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
}

.slide-wrapper {
  height: 100%;
}

.overlay-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1em;
  height: 100%;
}

.controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: black;
  font-size: 1.3em;
}

.controls img {
  width: 1.2em;
  padding: 0em 1em;
}

@media all and (display-mode: fullscreen) {
  .controls-fullscreen {
    display: none;
  }  
}

/* ----- */

.logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo-wrapper img {
  width: 55%;
}

@media (max-aspect-ratio: 1/1) {
  .logo-wrapper img {
    width: 75%;
  }
}

.logo-wrapper p {
  margin-top: 1em;
  margin-left: 2em;
  margin-right: 2em;
  font-size: 1.5em;
  color: black;
}

/* ----- */

.screen-recommendation-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  height: 100%;
}

.screen-recommendation-wrapper img {
  margin-bottom: 1em;
  width: 15%;
}

@media (max-aspect-ratio: 1/1) {
  .screen-recommendation-wrapper img {
    width: 40%;
  }
}

.screen-recommendation-wrapper p {
  display: inline-block;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-top: 0.5em;
  margin-left: 2em;
  margin-right: 2em;
  color: black;
}

/* ------ */

.caption {
  margin: 1em 3em;
  display: inline-block;
  text-align: center;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding: 0.6em;
  color: black;
  background-color: white;
  border: 1px solid black;
  border-radius: 0.5em;
  margin-bottom: 1em;
}

/* ------ */

.requestscreen-recommendation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: yellow;
  border: 1px solid black;
  padding: 0em;
  border-radius: 0.6em;
  margin: 2em 0.5em;
  font-size: 0.8em;
  align-self: flex-start;
  transition: transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s;
}

.requestscreen-recommendation-hidden {
  transform: translateX(-130%);
}

.requestscreen-recommendation-shown {
  transform: translateX(0%);  
}

.requestscreen-recommendation-wrapper img {
  width: 4.5em;
}

.requestscreen-recommendation-wrapper p {
  display: inline-block;
  margin-block-start: 0px;
  margin-block-end: 0px;
  color: black;
  padding: 0.5em;
  width: 15em;
}

@media (max-aspect-ratio: 1/1) {
  .requestscreen-recommendation-wrapper p {
    width: 12em;
  }
}

/* ------------ */

.text-wrapper {
  height: 100%;
  margin: 0 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-wrapper h1 {
  margin-block-start: 0px;
}

.text-wrapper p {
  font-size: 1.2em;
  margin-inline-end: 0px;
}

/* ------------------- */

.info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info a {
  text-decoration: none;
  color: red;
}

.author {
  font-size: 1.3em;
  margin: 0em 1em;
}

.author-wide {
  display: block;
}

.author-narrow {
  display: none;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1em;
}

.links div {
  margin: 0em 1em;
  z-index: 999;
}

.links img {
  width: 1.5em;
  margin-right: 0.3em;
  transform: translateY(0.2em);
}

.credits {
  margin-top: 1em;
  font-size: 0.7em;
  z-index: 999;
}

@media (max-aspect-ratio: 1/1) {
  .links {
    flex-direction: column;
  }
  .author-wide {
    display: none;
  }
  .author-narrow {
    display: block;
  }
}
